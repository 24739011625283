import MainLayout from "./../Layout/index";
import Facts from "./../components/Facts";
import SorglosPaket from "./../components/SorglosPaket";
import { Row, Col, Divider } from "antd";
import styled from "styled-components";
import OilFinder from "./../components/OilFinder";

function FinderPage() {
  return (
    <MainLayout>
      <div>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} xl={{ span: 12, offset: 6 }}>
            <div style={{ marginTop: 36 }}>
              <div style={{ textAlign: "center" }}>
                <Headline> Schmierstofffinder</Headline>
              </div>
              <Wrapper>
                <OilFinder />
              </Wrapper>
            </div>
          </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default FinderPage;

const Wrapper = styled.div`
  font-size: 18px;
  background: white;
  padding: 12px;
`;

const Headline = styled.div`
  display: inline-block;

  color: black;
  margin-bottom: 16px;
  font-size: 2em;

  text-transform: uppercase;
  font-weight: bold;
  background: red;
  color: white;
  padding: 3px;
  font-style: italic;
`;

const SubHeadline = styled.p`
  color: black;

  background: #f0f2f5;
`;
