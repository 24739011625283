import React, { useState } from "react";
import { Modal } from "antd";
import { Result, Button, Alert } from "antd";

const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={<div className="font-bold text-2xl"> Wir bauen für Sie um </div>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Schließen"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <img alt="wir bauen" src="/wirbauenfuersie.png" />
        <div className=" mt-6"></div>
        <Alert
          message={
            <p className="text-2xl font-bold">
              Ab Montag, den 24. Januar 2022 freuen wir uns Sie wieder begrüßen
              zu dürfen
            </p>
          }
          type="warning"
        />
      </Modal>
    </>
  );
};

export default App;
