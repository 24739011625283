import MainLayout from "./../Layout/index";
import Facts from "./../components/Facts";
import SorglosPaket from "./../components/SorglosPaket";
import { Row, Col, Divider } from "antd";
import Banner from "./../components/Banner";
import UmbauModal from "./../components/UmbauModal";
function HomePage() {
  return (
    <MainLayout>
      <UmbauModal />
      <div>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} xl={{ span: 14, offset: 5 }}>
            <div style={{ marginTop: 36 }}>
              <Facts />
            </div>

            <Banner />

            <Divider />

            <div style={{ marginTop: 36 }}>
              <SorglosPaket />
            </div>
          </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default HomePage;
