import { useState, useEffect } from "react";
import { Menu, Button, Row, Col } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  HomeOutlined,
} from "@ant-design/icons";

import styled from "styled-components";
import { Link } from "react-router-dom";
import get from "lodash/get";
const RouterLink = styled(Link)`
  color: white;
  display: block;

  &:hover {
    color: red;
    cursor: pointer;
  }

  margin-bottom: 12px;
`;

const { SubMenu } = Menu;

const Nav = () => {
  const currentLocation = get(window, "location.pathname");

  const current = get(window, "location.pathname");
  console.log(current);
  return (
    <Wrapper>
      <FlexBox active={current == "/"}>
        <Button block type="text">
          <RouterLink to="/">Home</RouterLink>
        </Button>
      </FlexBox>
      <FlexBox active={current == "/schmierstoff-finder"}>
        <Button block type="text">
          <RouterLink to="/schmierstoff-finder">
            {" "}
            Schmierstoff-Finder
          </RouterLink>
        </Button>
      </FlexBox>

      <FlexBox active={current == "/standorte"}>
        <Button block type="text">
          <RouterLink to="/standorte"> Standorte</RouterLink>
        </Button>
      </FlexBox>
    </Wrapper>
  );
};

export default Nav;

const Wrapper = styled.div`
  margin-top: 2px;
  display: flex;
  background: white;
  overflow: auto;
`;

const FlexBox = styled.div`
  flex: 1;
  text-align: center;
  padding: 3px;
  font-weight: bold;

  ${(props) =>
    props.active
      ? ` border-bottom: 4px solid red`
      : " border-bottom: 4px solid white"};

  &:hover {
    border-bottom: 4px solid red;
  }
`;
