import { Alert } from "antd";

import styled from "styled-components";

import { Typography, Space } from "antd";

const { Text, Link } = Typography;

const SAlert = styled(Alert)`
  background: rgb(255, 229, 0);

  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 18x;
  font-weight: bold;
`;

const Banner = () => {
  return (
    <SAlert
      message={
        <div>
          Bei einer <Text underline>Paket-Auswahl</Text> kann der{" "}
          <Text underline>Luft-, Innenraum- und Kraftstofffilter </Text>{" "}
          erneuert werden. Hier berechen wir nur die Materialkosten.
        </div>
      }
      type="warning"
    />
  );
};

export default Banner;
