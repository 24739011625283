import { Timeline, Card, Row, Col, Divider, Badge } from "antd";
import styled from "styled-components";
import { ClockCircleOutlined, CheckOutlined,  } from "@ant-design/icons";
import FormatCurrency from "./../FormatCurrency";

import { List, Avatar } from "antd";


const Icon = () => {

    return <span style={{marginRight: 5}}><CheckOutlined style={{fontSize: '2em', color: 'red'}}/></span>
}
const data = [
  {
    title: <span>Markenprodukte <b><i>INKLUSIVE</i></b></span> ,
    desc: <div>Wir verbauen auschließlich Markenprodukte. Unsere Ölwechsel-Partner: <b>TOTAL Motoröle, Mahle Ölfilter</b> </div>,
  },
  {
    title: <span>Sofort-Service <b><i>INKLUSIVE</i></b></span> ,
    desc: <div>Keine Terminvereinbarung notwendig.</div>,
  },
  {
    title: <span>Öl-Service Servicerückstellung <b><i>INKLUSIVE</i></b></span> ,
    desc: <div>Service-Intervalle werden zurück gesetzt.  </div>,
  },
  {
    title: <span>Eintrag ins Service-Heft <b><i>INKLUSIVE</i></b></span> ,
    desc: <div></div>,
  },
];

const Facts = () => {
  return (
    <FactsWrapper>
      <div style={{ textAlign: "center" }}>
        <Headline>Rund-um-Sorglos-Paket - zu einem Preis</Headline>
      </div>

      <div>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={<><Icon/>{item.title}</>}
                description={item.desc}
              />
            </List.Item>
          )}
        />
      </div>
    </FactsWrapper>
  );
};

export default Facts;

const FactsWrapper = styled.div``;

const Headline = styled.div`
  display: inline-block;

  color: black;
  margin-bottom: 16px;
  font-size: 2em;

  text-transform: uppercase;
  font-weight: bold;
  background: red;
  color: white;
  padding: 3px;
  font-style: italic;
`;
