import styled from "styled-components";

function Logo({ width }) {
  return (
    <Wrapper>
      <img
        width={width ? width : 128}
        alt="logo"
        src="/logo-red.png"
      />
    </Wrapper>
  );
}

export default Logo;

const Wrapper = styled.span``;
