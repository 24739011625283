import MainLayout from "./../Layout/index";
import Facts from "./../components/Facts";
import SorglosPaket from "./../components/SorglosPaket";
import { Row, Col, Divider } from "antd";
import styled from "styled-components";
import OilFinder from "./../components/OilFinder";
import GoogleMaps from "./../components/GoogleMapsMap";
import { MapPin } from "lucide-react";
const FlexLink = styled.a`
  &:hover {
    color: red;
    cursor: pointer;
  }
`;

function FinderPage() {
  return (
    <MainLayout>
      <div>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} xl={{ span: 12, offset: 6 }}>
            <div style={{ marginTop: 36 }}>
              <div style={{ textAlign: "center" }}>
                <Headline> Standorte</Headline>
              </div>
              <div className="mb-40">
                <div className="space-y-2">
                  <div className="shadow-xl  bg-white w-full p-2">
                    <div className="block md:flex md:space-x-2 ">
                      <div className="flex-1">
                        <div className=" h-full flex items-center text-3xl font-bold justify-center">


                          <a
                            target="_blank"
                            href="https://goo.gl/maps/DSFm2VrXEXfHAbhu6"
                            className="m-0"
                            style={{ color: "red" }}
                          >
                            Frankfurt
                          </a>

                        </div>
                      </div>
                      

                      <div className="flex-1">
                        <div className="font-bold text-base">Adresse</div>
                        <div>
                          <p className="m-0">Heerstraße 78</p>
                          <p className="m-0"> 60488 Frankfurt/Main</p>
                          <p className="m-0 text-xs">
                            (Direkt an der Total-Tankstelle)
                          </p>
                        </div>
                      </div>

                      <div className="flex-1">
                        <div className="font-bold text-base">
                          Öffnungszeiten
                        </div>
                        <div className="">
                          <p className="m-0 text-xs font-bold">Mo-Fr</p>
                          <p className="m-0"> 09:00 - 18:00</p>
                          {/* <p className="m-0 font-bold text-xs">Samstag</p>
                          <p className="m-0"> 10:00 - 16:00</p> */}
                        </div>
                      </div>

                      <div className="flex-1">
                        <div className="font-bold text-base">Telefon</div>
                        <div>
                          <FlexLink href="tel:+496990749709">
                            069 - 907 49 709
                          </FlexLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="shadow-xl  bg-white w-full p-2">
                    <div className="block md:flex md:space-x-2 ">
                      <div className="flex-1">
                        <div className=" h-full flex items-center text-3xl font-bold justify-center">
                          
                          <a
                            target="_blank"
                            href="https://goo.gl/maps/DSFm2VrXEXfHAbhu6"
                            className="m-0 block whitespace-nowrap"
                            style={{ color: "red" }}
                          >
                            Wiesbaden 
                          </a>
                        </div>
                      </div>

                      <div className="flex-1">
                        <div className="font-bold text-base">Adresse</div>
                        <div>
                          <p className="m-0">Klarenthaler Str. 44</p>
                          <p className="m-0">65197 Wiesbaden</p>
                          <p className="m-0 text-xs">
                            (Direkt an der Tankstelle)
                          </p>
                        </div>
                      </div>

                      <div className="flex-1">
                        <div className="font-bold text-base">
                          Öffnungszeiten
                        </div>
                        <div className="">
                        <p className="m-0 text-xs font-bold">Mo-Fr</p>
                          <p>09:00 - 18:00</p>
                        </div>
                      </div>

                      <div className="flex-1">
                        <div className="font-bold text-base">Telefon</div>
                        <div>
                          <FlexLink href="tel:+4961171028011">
                            0611 - 710 28 011
                          </FlexLink>
                        </div>
                      </div>
                    </div>
                  </div>

          

                  <div className="shadow-xl  bg-white w-full p-2">
                    <div className="block md:flex md:space-x-2 ">
                      <div className="flex-1">
                        <div className=" h-full flex items-center text-3xl font-bold justify-center md:justify-left mb-4">
                          
                          <a
                            target="_blank"
                            href="#"
                            className="m-0 block whitespace-nowrap"
                            style={{ color: "red" }}
                          >
                            Hofheim am Taunus 
                          </a>
                        </div>
                      </div>

                      <div className="flex-1 animate-pulse">
                      <div className="shadow-xl  bg-white w-full p-2 border-2 border-red-600">
                    <div className="block md:flex md:space-x-2 ">
          

                      <div className="flex-1 text-xl md:text-3xl font-bold text-center ">
                      Demnächst auch in Hofheim am Taunus
                      </div>


            
                    </div>
                  </div>
                      </div>

               

                
                    </div>
                  </div>

                  {/* <Row>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Ort>Frankfurt</Ort>
                      <p>Heerstraße 78</p>
                      <p>60488 Frankfurt/Main</p>
                      <p>(Direkt an der Total-Tankstelle)</p>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Ort>Demnächst auch in: Wiesbaden</Ort>
                      <p>Mr Oil Wiesbaden</p>
                      <p>Klarenthaler Str. 44, 65197 Wiesbaden</p>
                    </Col>
                  </Row> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default FinderPage;

const Ort = styled.p`
  font-size: 18px;
  font-weight: bold;
`;
const Wrapper = styled.div`
  font-size: 18px;
  background: white;
  padding: 12px;
`;

const Headline = styled.div`
  display: inline-block;

  color: black;
  margin-bottom: 16px;
  font-size: 2em;

  text-transform: uppercase;
  font-weight: bold;
  background: red;
  color: white;
  padding: 3px;
  font-style: italic;
`;

const SubHeadline = styled.p`
  color: black;

  background: #f0f2f5;
`;
