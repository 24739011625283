import styled from "styled-components";
import { List, Typography, Divider, Row, Col, Card, Rate } from "antd";
import get from "lodash/get";
import { Collapse } from "antd";
import Specs from "./CarSpecs";
import ResultRow from "./Result";
import { BarRow as OlPlan } from "./../../Facts";

const { Panel } = Collapse;

function CarList(props) {
  const data = get(props, "data", []);

  return (
    <Wrapper>
      <div style={{ marginLeft: 2 }}>Suchergebnisse</div>

      <Collapse style={{ border: "none" }} size="small">
        {data.map((item, i) => {
          const name = get(item, "car.name");
          const modelYears = get(item, "car.modelYears");
          const vehiclename = get(item, "car.vehiclename");
          const years = get(item, "car.year");
          const modell = get(item, "car.modell");
          let changeInterval = get(item, "changeInterval");
          let carSpecs = get(item, "carSpecs", []);
          let recommendations = get(item, "recommendations", []);

          const maxFilling = get(item, "maxFilling");

          const row = [name, vehiclename, modell, years];

          recommendations = [0].map((x) => {
            return (
              <Row>
                {recommendations.map((re) => {
                            const name = get(re, "name", "");
                            const stars = get(re, "stars", 0);
                            const use = get(re, "use", "").replace("Einsatz:", "");
                            const temperature = get(re, "tempRage", "")
                            debugger

                  return (
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      lg={{ span: 10, offset: 1 }}
                    >
                      <Card
                        size="small"
                        extra={<Rate disabled defaultValue={5-stars} />}
                      >
                        <Specs label={"Produktname"} value={name}/>
                        <Specs label={"Einsatz"} value={use}/>
                        <Specs label={"Temperatur"} value={temperature}/>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            );
          });

          changeInterval = changeInterval.map((x) => (
            <Specs
              highlight
              label={"Wechselintervall (" + x.useType + ")"}
              value={x.value}
            />
          ));

          carSpecs = carSpecs.map((x) => (
            <Specs label={x.label} value={x.value} />
          ));

          const specs = [];
          specs.push(
            <Specs highlight label={"Füllmenge"} value={maxFilling} />
          );

          return (
            <Panel header={row.join(" ,")} key={name + i}>
              {carSpecs}
              <Divider />
              {specs}
              {changeInterval}
              <Divider orientation={"left"}>Empfehlungen</Divider>
              {recommendations}
            </Panel>
          );
        })}
      </Collapse>
    </Wrapper>
  );
}

export default CarList;

const Wrapper = styled.span``;

const FlexWrapper = styled.div`
  display: flex;
`;

const Flex = styled.div`
  flex: 1;
`;
