import logo from "./logo.svg";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

import "./App.css";
import HomePage from "./pages/HomePage";
import Router from "./Router"

function App() {
  return <Router/>;
}

export default App;
