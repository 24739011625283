import styled from "styled-components";
import FinderForm from "./Form";
import Faq from "./Faq";
import { Divider } from "antd";
function OilFinder() {
  return (
    <Wrapper>
      <div>
        Bitte beachten Sie, dass der Ölfinder derzeit nicht funktioniert.
      </div>

      <div className="text-xs">
        Wir arbeiten derzeit an der Umsetzung des neuen Ölfinders. In der
        Zwischenzeit können Sie folgenden Ölfinder nutzen:{" "}
      </div>

      <div className="mt-4">
        <img width={75} src="/logo.webp"></img>
        <a
          target="_blank"
          href="https://totalenergies.de/services/schmierstoffe/schmierstofffinder"
        >
          Schmierstofffinder von TotalEnergies
        </a>
      </div>
      {false && (
        <div>
          <p>HSN & TSN des Fahrzeugs eingeben</p>
          <FinderForm />

          <Divider style={{ marginTop: 48, marginBottom: 0 }}>
            Hilfestellung
          </Divider>
          <br />

          <Faq />
        </div>
      )}
    </Wrapper>
  );
}

export default OilFinder;

const Wrapper = styled.span``;
