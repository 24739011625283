import styled from "styled-components";
import { Row, Col } from "antd";
import Logo from "./../Logo";
import { Link } from "react-router-dom";

import {
  PhoneOutlined,
  MailOutlined,
  InstagramOutlined,
  FacebookOutlined,
} from "@ant-design/icons";

function Footer() {
  return (
    <Wrapper>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <Row>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 4, offset: 4 }}>
              <Logo />
             
              <RouterLink to="/impressum">Impressum</RouterLink>
           
              <FlexWrapper>
                <Flex>
                  <FlexLink
                    target="_blank"
                    href="https://www.instagram.com/mr._oil/"
                  >
                    <InstagramOutlined
                      style={{ fontSize: 24, marginRight: 6 }}
                    />{" "}
                  </FlexLink>
                </Flex>
                <div>
                  <FlexLink
                    target="_blank"
                    href="https://www.facebook.com/Mroilfrankfurt/"
                  >
                    <FacebookOutlined style={{ fontSize: 24 }} />{" "}
                  </FlexLink>
                </div>
              </FlexWrapper>
            </Col>

            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
              <h1 className="text-xl" style={{ color: "red" }}>Frankfurt:</h1>
              <Contact />
            </Col>

            <Col
            className="mt-20 md:mt-0"
            xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
              <h1 className="text-xl" style={{ color: "red" }}>Wiesbaden:</h1>
              <ContactWi />
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default Footer;

const Wrapper = styled.div`
  background: black;
  color: white;
  padding: 26px;
  margin-top: 26px;
`;

const ContactWi = () => {
  return (
    <>
      <div>
        <Row>
          <Col span={10}>
            <PhoneOutlined /> Telefonnummer:
          </Col>
          <Col span={14}>
            <FlexLink href="tel:+4961171028011">0611 - 710 28 011</FlexLink>
          </Col>
        </Row>
      </div>

      <div>
        <Row>
          <Col span={10}>
            <MailOutlined /> E-Mail:
          </Col>
          <Col span={14}>
            <FlexLink>info@mroil.de</FlexLink>
          </Col>
        </Row>
      </div>

      <Row>
        <Col span={24}>
          <br />
          Klarenthaler Str. 44 <br />
          65197 Wiesbaden (Direkt an der Tankstelle)
          <br />
          <br />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <h1 style={{ color: "white" }}>Öffnungszeiten:</h1>

          <Row>
            <Col span="10"> MONTAG-FREITAG</Col>
            <Col span="14"> 09:00-18:00 UHR</Col>
          </Row>

         
        </Col>
      </Row>
    </>
  );
};

const Contact = () => {
  return (
    <>
      <div>
        <Row>
          <Col span={10}>
            <PhoneOutlined /> Telefonnummer:
          </Col>
          <Col span={14}>
            <FlexLink href="tel:+496990749709">069 - 907 49 709</FlexLink>
          </Col>
        </Row>
      </div>

      <div>
        <Row>
          <Col span={10}>
            <MailOutlined /> E-Mail:
          </Col>
          <Col span={14}>
            <FlexLink>info@mroil.de</FlexLink>
          </Col>
        </Row>
      </div>

      <Row>
        <Col span={24}>
          <br />
          Heerstraße 78 <br />
          60488 Frankfurt/Main (Direkt an der Total-Tankstelle)
          <br />
          <br />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <h1 style={{ color: "white" }}>Öffnungszeiten:</h1>

          <Row>
            <Col span="10"> MONTAG-FREITAG</Col>
            <Col span="14"> 09:00-18:00 UHR</Col>
          </Row>

          <Row>
            <Col span="10"> SAMSTAG</Col>
            <Col span="14"> 10:00-16:00 UHR</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const FlexWrapper = styled.div`
  display: flex;
`;

const Flex = styled.div``;

const FlexLink = styled.a`
  color: white;

  &:hover {
    color: red;
    cursor: pointer;
  }
`;

const RouterLink = styled(Link)`
  color: white;
  display: block;

  &:hover {
    color: red;
    cursor: pointer;
  }

  margin-bottom: 12px;
`;
