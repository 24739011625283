import { Collapse } from "antd";
import styled from "styled-components"
const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}


const Faq = () => {
  return (
    <Collapse style={{border: 'none'}}  defaultActiveKey={["0"]} onChange={callback}>
      <SPanel header="Wo finde ich HSN und TSN?" key="1">
        <p>
          Diese Informationen entnehmen Sie bitte der Zulassungsbescheinigung
          bzw. dem Fahrzeugschein
        </p>

        <p>
          <img
            style={{ width: "100%" }}
            src="/fahrzeugSchein.jpg"
            alt=""
          />
        </p>
      </SPanel>
      <SPanel header="Herstellerschlüsselnummer (HSN)" key="2">
        <p>
          Die HSN ist ein vierstelliger, numerischer Code. Wie der Name schon
          sagt, ordnet sie ein Fahrzeug eindeutig dem Hersteller zu.
        </p>
      </SPanel>
      <SPanel header="Typschlüsselnummer (TSN)" key="3">
        <p>
          Die TSN ist ein achtstelliger Code, bestehend aus drei Buchstaben und
          fünf Zahlen. Bis 2005 bestand sie nur aus Zahlen. Die ersten drei
          Stellen klassifizieren das Modell, während die fünf letzten Werte über
          Karosserieform, Motorisierung und Kraftstoffenergiequelle Aufschluss
          geben.
        </p>
      </SPanel>
    </Collapse>
  );
};

export default Faq;

const SPanel = styled(Panel)`

  background: white;
`
