import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import HomePage from "./pages/HomePage";
import Home from "./pages/v2/Home";
import ImpressumPage from "./pages/Impressum";
import OlFinderPage from "./pages/OlFinder";
import StandortPage from "./pages/StandortPage";
export default function App() {
  return (
    <Router>
      <div>
        <ScrollToTop />

        <Switch>
          <Route path="/impressum">
            <ImpressumPage />
          </Route>

          <Route path="/schmierstoff-finder">
            <OlFinderPage />
          </Route>

          <Route path="/standorte">
            <StandortPage />
          </Route>

          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
