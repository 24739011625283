import { Badge } from "antd";
import styled from "styled-components";
import { Row, Col } from "antd";
const Specs = ({ label, value, highlight}) => {

const ValueComp = () => {

    if(highlight) return <Code>{value}</Code>

    return <span>{value}</span>

}
  return (
    <Row>
      <Col span={12}>
        <Bold>{label}</Bold>
      </Col>
      <Col span={12}><ValueComp/></Col>
    </Row>
  );
};

export default Specs;

const Code = styled.code`
  margin: 0 1px;
  padding: 0.2em 0.4em;
  font-size: 0.9em;
  background: #f2f4f5;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const StyledP = styled.p`
  margin: 0;
`;
