import styled from "styled-components";
import Logo from "./../Logo";

import { Row, Col } from "antd";
import Nav from "./../Nav";

const Header = (props) => {
  return (
    <SHeader>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }}>
          <ContentWrapper className="flex items-center justify-center space-x-2">
            <div className="space-y-2">
              <div>
                <div className="bg-black text-base md:text-xl text-white mb-2 whitespace-nowrap p-1 md:p-1">
                  Marken-Öl
                </div>
                <img
                  width={100}
                  id="teal-logo"
                  src="/logo_totalenergies.webp"
                  alt="TotalEnergies"
                ></img>
              </div>
              <div>
                <div className="bg-black  text-base md:text-xl text-white mb-2 whitespace-nowrap p-1 md:p-1">
                  Marken-Filter
                </div>
                <img
                  width={100}
                  id="teal-logo"
                  src="/10223.png"
                  alt="Mahle"
                ></img>
              </div>
            </div>

            <Logo width={300} />
          </ContentWrapper>
        </Col>

        <Col span={8}></Col>
      </Row>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 10, offset: 7 }}>
          <NavWrapper>
            <Row>
              <Col style={{ background: "white" }} span={12} offset={6}>
                {" "}
                <Slogon>ÖLWECHSEL SOFORT-SERVICE</Slogon>
              </Col>
            </Row>

            <Nav />
          </NavWrapper>
        </Col>
      </Row>
    </SHeader>
  );
};

export default Header;

const SHeader = styled.div`
  background: rgb(255, 229, 0);

  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px 0px;
  margin-bottom: 16px;
`;

const ContentWrapper = styled.div`
  text-align: center;
`;

const NavWrapper = styled.div`
  text-align: center;
`;

const Slogon = styled.div`
  background: red;
  font-weight: bold;
  color: white;
  font-style: italic;
`;
