import { Badge } from "antd";
import styled from "styled-components";
const Specs = () => {
  return (
    <>
      <br />
      <Code>sdsd</Code>
    </>
  );
};

export default Specs;

const Code = styled.code`
  margin: 0 1px;
  padding: 0.2em 0.4em;
  font-size: 0.9em;
  background: #f2f4f5;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
`;
