import { Layout } from "antd";
import HeaderComp from "./../components/Header";
import Footer from "./../components/Footer";
import styled from "styled-components";
import TopBanner from "./../components/TopBanner";
import FlyerModal from "./../components/Flyer"

const MainLayout = (props) => {
  return (
    <>
      <SLayout>
        <TopBanner />
        {/* <FlyerModal /> */}
        <HeaderComp />

        <div>{props.children}</div>
        <div>
          <Footer />
        </div>
      </SLayout>
    </>
  );
};

export default MainLayout;

const SLayout = styled(Layout)``;
