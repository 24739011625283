import MainLayout from "./../Layout/index";
import Facts from "./../components/Facts";
import SorglosPaket from "./../components/SorglosPaket";
import { Row, Col, Divider } from "antd";
import styled from "styled-components";

function ImpressumPage() {
  return (
    <MainLayout>
      <div>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 16, offset: 4 }}>
            <div style={{ marginTop: 36 }}>
              <Headline> Impressum</Headline>

              <Wrapper>
               
                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>
                  Salih Boukajouj
                  <br />
                  PKW &Ouml;l-Wechsel. Inspektion und Wartungsarbeiten.
                  <br />
                  Heerstra&szlig;e, 78
                  <br />
                  60488 Frankfurt am Main
                </p>
                <h2>Kontakt</h2>
                <p>
                  Telefon: 0 69/90749 709
                  <br />
                  E-Mail: info@mroil.de
                </p>
                <h2>Umsatzsteuer-ID</h2>
                <p>
                  Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27
                  a Umsatzsteuergesetz:
                  <br />
                  folgt
                </p>
                <h2>
                  Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
                </h2>
                <p>
                  Wir sind nicht bereit oder verpflichtet, an
                  Streitbeilegungsverfahren vor einer
                  Verbraucherschlichtungsstelle teilzunehmen.
                </p>
                <h3>Haftung f&uuml;r Inhalte</h3>{" "}
                <p>
                  Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1
                  TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den
                  allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis
                  10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                  &uuml;bermittelte oder gespeicherte fremde Informationen zu
                  &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf
                  eine rechtswidrige T&auml;tigkeit hinweisen.
                </p>{" "}
                <p>
                  Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                  Informationen nach den allgemeinen Gesetzen bleiben hiervon
                  unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch
                  erst ab dem Zeitpunkt der Kenntnis einer konkreten
                  Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
                  entsprechenden Rechtsverletzungen werden wir diese Inhalte
                  umgehend entfernen.
                </p>{" "}
                <h3>Haftung f&uuml;r Links</h3>{" "}
                <p>
                  Unser Angebot enth&auml;lt Links zu externen Websites Dritter,
                  auf deren Inhalte wir keinen Einfluss haben. Deshalb
                  k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine
                  Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der
                  verlinkten Seiten ist stets der jeweilige Anbieter oder
                  Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                  wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche
                  Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige
                  Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                </p>{" "}
                <p>
                  Eine permanente inhaltliche Kontrolle der verlinkten Seiten
                  ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
                  nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                  werden wir derartige Links umgehend entfernen.
                </p>
                Quelle:
                <br />
                https://www.e-recht24.de
              </Wrapper>
            </div>
          </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default ImpressumPage;

const Wrapper = styled.div`
  font-size: 18px;
  background: white;
  padding: 12px;
`;

const Headline = styled.div`
  display: inline-block;

  color: black;
  margin-bottom: 16px;
  font-size: 2em;

  text-transform: uppercase;
  font-weight: bold;
  background: red;
  color: white;
  padding: 3px;
  font-style: italic;
`;
