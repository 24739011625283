import React, { useState, useEffect } from "react";
import { Form, Input, Button, Divider, Row, Col, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import styled from "styled-components";
import axios from "axios";
import get from "lodash/get";
import SchmierstoffResult from "../../SchmierstoffResult";
import CarList from "./../CarList";

import { Alert } from "antd";

const HorizontalLoginForm = () => {
  const [hsn, setHsn] = useState();
  const [tsn, setTsn] = useState();
  const [carResult, setCarResult] = useState(null);
  const [showSpin, setShowSpin] = useState(false);
  const handleFinish = async () => {
    if (!(hsn && tsn)) return;
    try {
      setCarResult(null);
      setShowSpin(true);
      const req = await axios.get("api/search/hsn/" + hsn + "/tsn/" + tsn);
      const data = get(req, "data", []);
      setShowSpin(false);

      console.log(data);
      setCarResult(data);
    } catch (e) {
      console.log(e);
    }
  };

  const errorText = "Kein Ergebnis gefunden";

  return (
    <>
      <Form  onFinish={(e) => console.log("ff")}>
        <Row>
          <Col xs={{ span: 12 }} lg={{ span: 4 }}>
            <FormItemWrapper>
              <Form.Item name="note">
                <Input
                  onChange={(e) => setHsn(e.target.value)}
                  allowClear
                  placeholder="HSN"
                />
              </Form.Item>
            </FormItemWrapper>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 4 }}>
            <FormItemWrapper>
              <Form.Item name="notes">
                <Input
                  onChange={(e) => setTsn(e.target.value)}
                  allowClear
                  placeholder="TSN"
                />
              </Form.Item>
            </FormItemWrapper>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 4 }}>
            <FormItemWrapper>
              <Button
                htmlType="submit"
                onClick={hsn && tsn ? handleFinish : null}
                disabled={!(hsn && tsn)}
                block
                type="primary"
                loading={showSpin}
                //disabled={true}
              >
                Suchen
              </Button>
            </FormItemWrapper>
          </Col>
        </Row>
      </Form>
      {showSpin && <Spin />}
      {/* {carResult && !showSpin && <CarList data={carResult} />} */}
      {carResult && !showSpin && !carResult.error && (
        <SchmierstoffResult hsn={hsn} tsn={tsn} data={carResult} />
      )}
      {carResult?.error && <Alert message={errorText} type="error" />}
      {/* <Alert
        message={"Diese Funktion steht derzeit nicht zur Verfügung"}
        type="error"
      /> */}
    </>
  );
};
export default HorizontalLoginForm;

const FormItemWrapper = styled.div`
  margin: 6px;
`;
