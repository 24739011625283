import React from "react";
import Facts from "./../../components/Facts";

function Home() {
  return (
    <div
      className="w-full h-screen bg-yellow-300 flex items-center justify-center"
      onScroll={console.log}
    >
      <div className="w-full h-screen ">
        <img
          style={{ width: "50vw" }}
          alt="logo"
          src="/logo-red.png"
        />
      </div>
      <div className="w-full h-screen bg-yellow-300 flex items-center justify-center">
        <Facts />
      </div>
    </div>
  );
}

export default Home;
