import { Timeline, Card, Row, Col, Divider, Badge } from "antd";
import styled from "styled-components";
import { ClockCircleOutlined, CheckOutlined } from "@ant-design/icons";
import FormatCurrency from "./../FormatCurrency";
const Facts = () => {
  const packagePrefix = "Paket ";
  return (
    <FactsWrapper>
      <div style={{ textAlign: "center" }}>
        <Headline>Ölwechsel, zum Komplettpreis</Headline>
      </div>

      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <BarRow text={`${packagePrefix} 1`} value="10W 40" price="49.90" />
          </Col>
          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <BarRow text={`${packagePrefix} 2`} value="5W 40" price="79.90" />
          </Col>

          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <BarRow text={`${packagePrefix} 3`} value="5W 30" price="99.90" />
          </Col>

          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <BarRow text={`${packagePrefix} 4`} value="0W 30" price="109.90" />
          </Col>

          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <BarRow text={`${packagePrefix} 5`} value="0W 40" price="109.90" />
          </Col>

          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <BarRow text={`${packagePrefix} 6`} value="0W 20" price="109.90" />
          </Col>

          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <BarRow text={`${packagePrefix} 7`} value="5W 20" price="109.90" />
          </Col>
          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <BarRow text={`${packagePrefix} 8`} value="10W 60" price="129.90" />
          </Col>
        </Row>
      </div>
    </FactsWrapper>
  );
};

export const BarRow = ({ text, value, price }) => {
  return (
    <Card style={{ marginBottom: 12 }} size="small" bordered={false}>
      <PriceWrapper>
        <Plan> {text}</Plan>
        <PriceTop> {value} </PriceTop>
        <div>
          <p style={{ margin: 0, fontSize: "1.5em" }}>
            <FormatCurrency number={price} />{" "}
          </p>
          <p style={{ fontSize: "12px", margin: 0 }}> inkl. MwSt </p>

          <p
            style={{
              fontSize: "12px",
              marginTop: 12,
              opacity: 0.6,
              padding: 2,
            }}
          >
            Busse, OFF-Road Fahrzeuge und PKW mit über 6 Litern Öl, werden mit
            zuzüglich <FormatCurrency number="15" /> Aufpreis verrechnet
          </p>
        </div>
      </PriceWrapper>

      <div>
        <p style={{ fontWeight: "bold" }}>Im Preis inbegriffen </p>

        <div>
          <div>
            <CheckOutlined /> <span> Öl</span>{" "}
          </div>
          <div>
            <CheckOutlined /> <span>Ölfilter</span>{" "}
          </div>
          <div>
            <CheckOutlined /> <span>Ölablassschraube</span>{" "}
          </div>
          <div>
            <CheckOutlined /> <span>Arbeitslohn</span>{" "}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Facts;

const FactsWrapper = styled.div``;

const OwnBadge = styled.div`
  color: black;
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px 0px;
  border-radius: 2px;
  padding: 6px;
  padding-left: 24px;
  padding-right: 24px;
  position: absolute;
  right: 0;
  border-radius: 2px;
`;

const PriceWrapper = styled.div`
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 1.5em;
  text-align: center;
`;

const PriceTop = styled.div`
  background: black;
  color: white;
`;

const Plan = styled.div`
  background: white;
  color: black;
`;

const Headline = styled.div`
  display: inline-block;

  color: black;
  margin-bottom: 16px;
  font-size: 2em;

  text-transform: uppercase;
  font-weight: bold;
  background: red;
  color: white;
  padding: 3px;
  font-style: italic;
`;
