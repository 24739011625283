import { Alert } from "antd";
import styled from "styled-components";

const onClose = (e) => {
  // console.log(e, "I was closed.");
};

const TopBanner = () => {
  return (
    <SAlert
      message={
        <p className="text-white animate-bounce text-3xl md:text-6xl font-bold m-0">
          <span> Neueröffnung in Wiesbaden</span>
          <br />
         
          {/* <p className="text-xs">
            {" "}
            Nur ein Auto pro Kunde, so lange der Vorrsat reicht
          </p> */}
        </p>
      }
      type="warning"
      closable={false}
      onClose={onClose}
    />
  );

  return (
    <SAlert
      message={<Text>Wir sind für Sie da.</Text>}
      type="warning"
      closable={false}
      onClose={onClose}
    />
  );
};

export default TopBanner;

const SAlert = styled(Alert)`
  background: red;

  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 16px;
`;

const Text = styled.div`
  text-decoration: underline;
  text-shadow: 0px 0px black;
  color: white;
  font-size: 1.25em;
`;
