import React from "react";
import styled from "styled-components";
import _get from "lodash/get";
const Headline = styled.div`
    text-transform: uppercase;
    font-weight: bold;
   
    color: white;
    padding: 3px;
    font-style: italic;
}
`;

const Image = ({ data, width, height }) => (
  <img width={width} height={height} src={`data:image/jpeg;base64,${data}`} />
);

function CarDetails({ data, hsn, tsn }) {
  const makeName = _get(data, "producer.makeName");
  const modelImage = _get(data, "producer.producerIcon");
  const carImage = _get(data, "modelImage");
  const typeName = _get(data, "typeName", "");
  const modelCode = _get(data, "modelCode", "");
  const yearStart = _get(data, "yearStart", "");
  const yearEnd = _get(data, "yearEnd", "");
  const modelName = _get(data, "modelName", "");
  const driveType = _get(data, "driveType", "");
  const fuel = _get(data, "fuel", "");
  const engineBuild = _get(data, "engineBuild", "");
  const cylinderCount = _get(data, "cylinderCount", "");
  const valveCount = _get(data, "valveCount", "");
  const cylinderCC = _get(data, "cylinderCC", "");
  const powerHP = _get(data, "powerHP", "");
  const powerKW = _get(data, "powerKW", "");
  const capacitiesValue = _get(data, "capacities.value", "");
  const capacitiesText = _get(data, "capacities.text", "");
  const capacitiesItem = _get(data, "capacities.item", "");

  const carModelName = modelName + " " + modelCode;
  const carVariant = typeName + " (" + yearStart + " - " + yearEnd + ")";
  return (
    <div>
      <div className="">
        <Headline className="bg-gray-500 flex items-center">
          <div className="flex-1">Ihr Fahrzeug</div>
          <div className="flex-1 text-right text-base mr-1">
            HSN: {hsn} TSN: {tsn}
          </div>
        </Headline>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3  bg-gray-50 text-sm p-2 ">
          <div className="bg-gray-100 p-2 shadow-sm">
            {carImage && <Image data={carImage} width={150} height={50} />}

            <div className="flex items-end space-x-2 mb-2">
              <div>
                {modelImage && (
                  <Image data={modelImage} width={50} height={50} />
                )}
              </div>
              <div className="text-3xl">{makeName}</div>
            </div>
            <div className="text-lg font-medium m-0">
              <span className="bg-gray-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                {carModelName}
              </span>
            </div>
            <div className="text-lg font-medium m-0">
              <span className="bg-gray-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                {carVariant}
              </span>
            </div>
          </div>
          <div>
            <div className="flex space-x-14">
              <ul>
                <li>Antriebsart</li>
                <li>Kraftstoff</li>
                <li>Kraftstoff</li>
                <li>Zylinder</li>
                <li>Ventile pro Zylinder</li>
                <li>Hubraum</li>
                <li>Nennleistung</li>
              </ul>

              <ul className=" font-bold m-0">
                <li>{driveType}</li>
                <li>{fuel}</li>
                <li>{engineBuild}</li>
                <li>{cylinderCount}</li>
                <li>{valveCount}</li>
                <li>{cylinderCC} cc</li>
                <li>
                  {powerHP} PS / {powerKW} kW
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const getProductPrice = (productName) => {
  const price = {
    "10W-40": "39,90",
    "5W-40": "69,90",
    "5W-30": "89,90",
    "0W-30": "99,90",
    "0W-40": "99,90",
    "0W-20": "99,90",
    "5W-20": "99,90",
    "10W-60": "119,90",
  };

  const keys = Object.keys(price);

  let priceResult = "";
  for (const key of keys) {
    const s = productName.split(" ");

    const f = s.find((k) => k == key);

    if (f) priceResult = price[f];
  }

  return priceResult || "Preis auf Anfrage";
};

const Recommendations = ({ data, hsn, tsn }) => {
  const makeName = _get(data, "producer.makeName");
  const modelImage = _get(data, "producer.producerIcon");
  const typeName = _get(data, "typeName", "");
  const modelCode = _get(data, "modelCode", "");
  const yearStart = _get(data, "yearStart", "");
  const yearEnd = _get(data, "yearEnd", "");
  const modelName = _get(data, "modelName", "");
  const driveType = _get(data, "driveType", "");
  const fuel = _get(data, "fuel", "");
  const engineBuild = _get(data, "engineBuild", "");
  const cylinderCount = _get(data, "cylinderCount", "");
  const valveCount = _get(data, "valveCount", "");
  const cylinderCC = _get(data, "cylinderCC", "");
  const powerHP = _get(data, "powerHP", "");
  const powerKW = _get(data, "powerKW", "");
  const capacitiesValue = _get(data, "capacities.value", "");
  const capacitiesText = _get(data, "capacities.text", "");
  const capacitiesItem = _get(data, "capacities.item", "");
  const recommendations = _get(data, "recommendations", []);

  const carModelName = modelName + " " + modelCode;
  const carVariant = typeName + " (" + yearStart + " - " + yearEnd + ")";
  return (
    <div>
      <div className="">
        <Headline className="bg-gray-500 flex items-center">
          <div className="flex-1">Motor-Öl Empfehlungen</div>
        </Headline>

        {recommendations.map((x) => {
          const productImage = _get(x, "productImage");
          const productName = _get(x, "productName");
          const temperatureName = _get(x, "temperatureName");
          const useName = _get(x, "useName");
          const intervalName = _get(x, "intervals.0.intervalName", "");
          const price = getProductPrice(productName);

          return (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3  bg-gray-50 text-sm p-2 ">
              <div className="bg-gray-100 p-2 shadow-sm">
                <div className="text-2xl font-bold mb-4">{productName}</div>

                <div className="flex items-end   justify-center space-x-2 mb-2">
                  <div>
                    {productImage && (
                      <Image data={productImage} width={160} height={160} />
                    )}
                  </div>
                </div>
                <div className="text-lg font-medium m-0">
                  <span className="bg-gray-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                    {temperatureName}
                  </span>
                  <span className="bg-gray-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                    {intervalName}
                  </span>
                </div>
              </div>
              <div>
                <div className="flex space-x-14 justify-center items-center shadow-sm h-full">
                  <ul>
                    <li>Produkt</li>
                    <li>{capacitiesItem}</li>
                    <li>Jahreszeit</li>
                    <li>Wechselintervall</li>
                    <li>Preis</li>
                  </ul>

                  <ul className=" font-bold m-0">
                    <li>{productName}</li>
                    <li>
                      {capacitiesValue} {capacitiesText}{" "}
                    </li>
                    <li>{temperatureName}</li>
                    <li>{intervalName}</li>
                    <li>
                      ab<span className="text-2xl"> {price} EUR</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SchmierstoffResult = ({ data, hsn, tsn }) => {
  return (
    <div className="mt-6">
      <CarDetails data={data} hsn={hsn} tsn={tsn} />
      <Recommendations data={data} />
    </div>
  );
};
export default SchmierstoffResult;
